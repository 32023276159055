@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    input[type="checkbox"]:checked ~ label span svg {
        @apply inline-flex;
    }

    .sticky .header-logo {
        @apply py-5 lg:py-2;
    }

    .sticky .menu-scroll.active {
        @apply opacity-70;
    }

    input#togglePlan:checked ~ .dot {
        @apply translate-x-full;
    }

    input#checkboxLabel:checked ~ .box span {
        @apply opacity-100;
    }
}

html {
    scroll-behavior: smooth;
}


.bg {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 190px;
    height: 240px;
    z-index: 2;
    background: rgba(255, 255, 255, .95);
    backdrop-filter: blur(24px);
    border-radius: 10px;
    overflow: hidden;
    outline: 2px solid white;
}

.blob {
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100%);
    opacity: 1;
    filter: blur(12px);
    animation: blob-bounce 5s infinite ease;
}

.hero-video iframe {
    border-radius: 12px;
}

.hero-badge:before {
    content: '🚀';
    position: absolute;
    top: 40%;
    left: 15px;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    /*background: #40c9ff;*/
    border-radius: 12px;
    transform-origin: center;
    /*animation: flicker 1s infinite;*/
}

.hero-badge:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background: #40c9ff;
    border-radius: 12px;
    transform-origin: center;
    animation: flicker 1s infinite;
}

.product_line_dot_left:after {
    position: absolute;
    content: "";
    width: 25px;
    height: 25px;
    background: rgb(0, 105, 255);
    top: 50%;
    right: 0;
    transform: translate(0%, -50%) rotate(45deg);
}

.product_line_dot_right:after {
    position: absolute;
    content: "";
    width: 25px;
    height: 25px;
    background: rgb(0, 105, 255);
    top: 50%;
    left: 0;
    transform: translate(0%, -50%) rotate(45deg);
}

.lmtWIHO_gkbTeeyuvoJC {
    background: black;
}

@keyframes flicker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blob-bounce {
    0% {
        transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }

    25% {
        transform: translate(-100%, -100%) translate3d(100%, 0, 0);
    }

    50% {
        transform: translate(-100%, -100%) translate3d(100%, 100%, 0);
    }

    75% {
        transform: translate(-100%, -100%) translate3d(0, 100%, 0);
    }

    100% {
        transform: translate(-100%, -100%) translate3d(0, 0, 0);
    }
}

@keyframes marquee {
    0% {
        transform: translate3d(0%, 0, 0);
    }

    100% {
        transform: translate3d(-100%, 0, 0);
    }
}